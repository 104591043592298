<template>
    <div>
        <LayoutCustomerCareMenu></LayoutCustomerCareMenu>
        <div class="md:pl-64">
            <div class="mx-auto flex max-w-9xl flex-col md:px-8 xxl:px-0">
                <main class="flex-1">
                    <slot />
                </main>
            </div>
        </div>
    </div>
</template>
