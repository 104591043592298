<template>
    <TransitionRoot as="template" :show="sidebarOpen">
        <Dialog as="div" class="relative z-40 md:hidden" @close="sidebarOpen = false">
            <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </TransitionChild>

            <div class="fixed inset-0 z-40 flex">
                <TransitionChild
                    as="template"
                    enter="transition ease-in-out duration-300 transform"
                    enter-from="-translate-x-full"
                    enter-to="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leave-from="translate-x-0"
                    leave-to="-translate-x-full">
                    <DialogPanel class="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
                        <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                            <div class="absolute top-0 right-0 -mr-12 pt-2">
                                <button type="button" class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="sidebarOpen = false">
                                    <span class="sr-only">Chiudi menu</span>
                                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                                </button>
                            </div>
                        </TransitionChild>
                        <div class="flex flex-shrink-0 items-center">
                            <img class="h-8 w-auto" src="/img/logo.svg" alt="Sendback logo" />
                        </div>
                        <div class="mt-5 h-0 flex-1 overflow-y-auto">
                            <nav class="space-y-1 px-2">
                                <NuxtLink
                                    v-for="item in navigation"
                                    :key="item.name"
                                    :to="item.to"
                                    :class="[item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group rounded-md py-2 px-2 flex items-center text-base font-medium']">
                                    <component :is="item.icon" :class="[item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-4 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                                    {{ item.name }}
                                </NuxtLink>
                            </nav>
                        </div>
                    </DialogPanel>
                </TransitionChild>
                <div class="w-14 flex-shrink-0">
                    <!-- Dummy element to force sidebar to shrink to fit close icon -->
                </div>
            </div>
        </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div class="flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-white pt-5">
            <div class="flex flex-shrink-0 items-center ml-3.5">
                <img class="h-8 w-auto" src="/img/logo.svg" alt="SendBack logo" />
            </div>
            <div class="mt-5 flex flex-grow flex-col">
                <nav class="flex-1 space-y-1 px-2 pb-4">
                    <template v-for="item in navigation" :key="item.name">
                        <template v-if="item.submenus">
                            <div class="group rounded-md py-2 px-2 flex items-center text-sm font-medium transition-all cursor-pointer" @click="item.show = !item.show">
                                <component :is="item.icon" :class="['mr-3 flex-shrink-0 h-6 w-6 transition-all']" aria-hidden="true" />
                                {{ item.name }}
                            </div>
                            <div class="ml-9" :class="item.show ? '' : 'hidden'">
                                <NuxtLink
                                    v-for="subitem in item.submenus"
                                    :key="subitem?.name"
                                    :to="subitem?.to"
                                    :class="[
                                        route.name == subitem?.route_name ? 'bg-transparent !text-highlight' : 'text-gray-600 hover:bg-highlight/5 hover:text-highlight',
                                        'group rounded-md py-2 px-2 flex items-center text-sm font-medium transition-all',
                                    ]">
                                    {{ subitem.name }}
                                </NuxtLink>
                            </div>
                        </template>
                        <NuxtLink
                            v-else
                            :to="item.to"
                            :class="[
                                route.name == item.route_name ? 'bg-transparent !text-highlight' : 'text-gray-600 hover:bg-highlight/5 hover:text-highlight',
                                'group rounded-md py-2 px-2 flex items-center text-sm font-medium transition-all',
                            ]">
                            <component :is="item.icon" :class="[route.name == item.route_name ? 'text-highlight' : 'text-gray-600 group-hover:text-highlight', 'mr-3 flex-shrink-0 h-6 w-6 transition-all']" aria-hidden="true" />
                            {{ item.name }}
                        </NuxtLink>
                    </template>
                </nav>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
    import { SwatchIcon, ListBulletIcon, ArchiveBoxIcon, BuildingStorefrontIcon, UserIcon, HomeIcon, XMarkIcon } from "@heroicons/vue/24/outline";

    const navigation = ref([
        { name: "Home", to: "/home", route_name: "home", icon: HomeIcon },
        { name: "Utenti", to: "/customer-care/users", route_name: "customer-care-users", icon: UserIcon },
        { name: "Piani", to: "/customer-care/plans", route_name: "customer-care-plans", icon: ArchiveBoxIcon },
        // { name: "Analytics", to: "/analytics", route_name: "analytics", icon: ChartBarIcon },
    ]);

    const route = useRoute();
</script>
<script>
    export default {
        data: () => {
            return {
                sidebarOpen: false,
            };
        },
        mounted() {
            this.$bus.on("sidebar.open", () => {
                this.sidebarOpen = true;
            });
        },
    };
</script>
